import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiDragDropLine,
} from "react-icons/ri";
import { IoMdCloudUpload } from "react-icons/io";

import ImageInput from "../../../../components/form/ImageInput";
import { DropContainer, UploadMessage } from "./styles";

interface IUploadProps {
  onUpload: Function;
}

const Upload: React.FC<IUploadProps> = ({ onUpload }) => {
  const renderDragMessage = useCallback(
    (isDragActive: boolean, isDragReject: boolean) => {
      if (!isDragActive) {
        return (
          <UploadMessage>
            <IoMdCloudUpload />
            Clique ou arraste a foto aqui
          </UploadMessage>
        );
      }

      if (isDragReject) {
        return (
          <UploadMessage type="error">
            <RiCloseCircleLine />
            Formato de arquivo não suportado
          </UploadMessage>
        );
      }
      return (
        <UploadMessage type="success">
          <RiCheckboxCircleLine />
          Solte o arquivo aqui
        </UploadMessage>
      );
    },
    [],
  );

  return (
    <Dropzone
      accept={["image/jpg", "image/png", "image/jpeg"]}
      onDropAccepted={files => onUpload(files)}
      multiple={false}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
