import styled from "styled-components";

export const Container = styled.div`
  height: calc(100% - 45px);

  > main {
    height: 100%;

    display: flex;
  }
`;

export const Wrapper = styled.div`
  max-width: 1120px;
  width: 100%;

  min-height: calc(96vh - 45px);

  margin: 8px auto;
  padding: 8px;

  border-radius: 8px;

  /* background: #fff; */
`;
