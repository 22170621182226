import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #f4f4f4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 32px 96px;

  h1 {
    font-weight: 700;
    font-size: 3.2rem;
    color: #0d455f;
    text-align: center;
    margin-bottom: 32px;
  }

  main {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    align-self: center;

    margin: 24px;
  }
`;

export const OrderContainer = styled.div`
  background: #dfe6ed;

  padding: 8px 16px;

  section {
    /* & + section { */
    margin-top: 8px;
    /* } */
  }

  footer {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 16px;

    div {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 8px;

      button {
        border-radius: 4px;
        padding: 8px 16px;
      }
    }
  }
`;
