import React from "react";

import { Container, FileInfo } from "./styles";

interface IImageListProps {
  file?: File;
  image_url?: string;
}

const ImageList: React.FC<IImageListProps> = ({ file, image_url }) => {
  return (
    <Container>
      {(file || image_url) && (
        <FileInfo>
          <img
            src={file instanceof File ? URL.createObjectURL(file) : image_url}
          />
        </FileInfo>
      )}
    </Container>
  );
};

export default ImageList;
