import React, { useCallback, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";
import Axios from "axios";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import InputMask from "../../../components/form/Input/InputMask";
import api from "../../../services/api";
import { cnpjValidation } from "../../../utils/cnpjValidation";
import { cpfValidation } from "../../../utils/cpfValidation";
import getValidationErrors from "../../../utils/getValidationErrors";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, Content, InputsContainer, AcceptContainer } from "./styles";
import Button from "../../../components/Button";

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoadingCEPData, setIsLoadingCEPData] = useState(false);

  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const inputChecked = formRef.current?.getFieldRef("accept").checked;

      const uid_validation =
        data.cnpj.length > 11
          ? Yup.string()
              .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
              .required("Campo obrigatório")
              .test("test-cnpj", "CNPJ deve ser válido", value => {
                if (value) {
                  return cnpjValidation(value);
                }

                return false;
              })
          : Yup.string()
              .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
              .required("Campo obrigatório")
              .test("test-cpf", "CPF deve ser válido", value => {
                if (value) {
                  return cpfValidation(value);
                }

                return false;
              });

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        cnpj: uid_validation,
        phone: Yup.string().required("Campo obrigatório"),
        inscricao_estadual: Yup.string().required("Campo obrigatório"),
        contact_email: Yup.string().email().required("Campo obrigatório"),
        address: Yup.object().shape({
          state: Yup.string().required("Campo obrigatório"),
          city: Yup.string().required("Campo obrigatório"),
          zipcode: Yup.string().required("Campo obrigatório"),
          neighborhood: Yup.string().required("Campo obrigatório"),
          street: Yup.string().required("Campo obrigatório"),
          complement: Yup.string().required("Campo obrigatório"),
          number: Yup.string().required("Campo obrigatório"),
        }),
        ...(!inputChecked && {
          accept: Yup.string().required("Você deve marcar essa opção"),
        }),
      });

      try {
        await schema.validate(data, { abortEarly: false });

        await api.post("/registers", data);

        history.push("/sign-up-success");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.error("Favor verifique os campos");

          return;
        }

        toast.error(
          `Erro ao cadastrar. Favor, tente novamente. \n\n${handleMessageError(
            error,
          )}`,
        );
      }
    },
    [history],
  );

  const handleLoadCEPData = useCallback(async () => {
    const zipcode = formRef.current?.getFieldValue("address.zipcode");

    setIsLoadingCEPData(true);
    const { data } = await Axios.get(
      `https://brasilapi.com.br/api/cep/v1/${zipcode}`,
    ).finally(() => {
      setIsLoadingCEPData(false);
    });

    const address = {
      zipcode: data.cep,
      state: data.state,
      city: data.city,
      neighborhood: data.neighborhood,
      street: data.street,
    };

    const formProfileData = formRef.current?.getData();

    formRef.current?.setData({
      ...formProfileData,
      address,
    });
  }, []);

  return (
    <Container>
      <Content>
        <h1>Faça seu cadastro em nossa plataforma</h1>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <InputsContainer>
            <h2>Dados da empresa</h2>

            <div>
              <Input
                label="Nome da empresa"
                name="name"
                placeholder="Digite o nome"
              />
              <Input
                label="CPF/CNPJ da empresa (apenas dígitos)"
                name="cnpj"
                // maskType="cnpj"
                // alwaysShowMask
                // maskPlaceholder={null}
                placeholder="Digite o CPF/CNPJ da empresa"
              />

              <InputMask
                label="Telefone de contato"
                name="phone"
                maskType="phone"
                alwaysShowMask={false}
                maskPlaceholder={null}
                placeholder="Digite o Número  da empresa"
              />
              <Input
                label="Inscrição estadual da empresa"
                name="inscricao_estadual"
                placeholder="Digite o número"
              />
              <Input
                label="E-mail de contato"
                name="contact_email"
                type="email"
                placeholder="Digite o e-mail"
              />
            </div>
          </InputsContainer>

          <InputsContainer>
            <h2>Endereço</h2>

            <div>
              <Scope path="address">
                <Input
                  label=""
                  name="country"
                  defaultValue="br"
                  containerStyle={{ display: "none" }}
                />
                <InputMask
                  label="CEP"
                  name="zipcode"
                  maskType="cep"
                  alwaysShowMask
                  maskPlaceholder={null}
                  placeholder="Digite o CEP"
                  onBlur={handleLoadCEPData}
                />
                <Input
                  label="Estado"
                  name="state"
                  maxLength={2}
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite a sigla"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Cidade"
                  name="city"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Bairro"
                  name="neighborhood"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Rua"
                  name="street"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Número"
                  name="number"
                  placeholder="Digite o número"
                />
                <Input
                  label="Complemento"
                  name="complement"
                  placeholder="Digite o complemento"
                />
              </Scope>
            </div>
          </InputsContainer>

          <AcceptContainer>
            <Input label="" type="checkbox" name="accept" />
            <p>
              Li e concordo com as{" "}
              <a
                href="https://docs.google.com/document/d/1HlcX4DdBdWci3Nv5_LfZZEWUBt4NxgYeaw4uhs5f6XA/edit"
                target="__blank"
              >
                políticas de privacidade
              </a>{" "}
              e{" "}
              <a
                href="https://docs.google.com/document/d/1EGCPzaf7RIMrSmuBIyg9P0veCP7c7orXuO4Z77ti7ew/edit"
                target="__blank"
              >
                termos de uso
              </a>{" "}
            </p>
          </AcceptContainer>

          <Button type="submit">Criar conta</Button>
        </Form>

        <footer>
          <Link to="/">Voltar à tela de acesso</Link>
        </footer>
      </Content>
    </Container>
  );
};

export default SignUp;
