import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 16px;

  p {
    font-weight: 700;
    margin-bottom: 8px;
  }
`;
