import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";
import Axios from "axios";
import * as Yup from "yup";

import Input from "../../components/form/Input";
import InputMask from "../../components/form/Input/InputMask";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { cnpjValidation } from "../../utils/cnpjValidation";
import { cpfValidation } from "../../utils/cpfValidation";
import getValidationErrors from "../../utils/getValidationErrors";
import handleMessageError from "../../utils/handleMessageError";
import Payments from "./Payments";
import { Container, InputsContainer, FormContent } from "./styles";
import Button from "../../components/Button";

const Profile: React.FC = () => {
  const formRefAccess = useRef<FormHandles>(null);
  const formRefProfile = useRef<FormHandles>(null);
  const [isLoadingCEPData, setIsLoadingCEPData] = useState(false);

  const { user, updateUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const { search } = history.location;
    const force_register_account_bank = new URLSearchParams(search).get(
      "force_register_bank_account",
    );

    if (force_register_account_bank) {
      window.location.hash = "#payments";
    }
  }, [history.location]);
  const handleSubmitAccess = useCallback(
    async data => {
      formRefAccess.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Deve ser no formato de e-mail")
          .required("Campo obrigatório"),
        password: Yup.string(),
        new_password: Yup.string().when("password", {
          is: value => value.length,

          then: Yup.string().min(8, "A senha deve conter no mínimo 8 dígitos."),
        }),
        new_password_confirmation: Yup.string().when("new_password", {
          is: value => value.length,

          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "As senhas devem ser iguais",
          ),
        }),
      });

      const toastId = toast.info("Atualizando informações...", {
        autoClose: false,
      });

      try {
        await schema.validate(data, { abortEarly: false });
        const response = await api.put("/profiles", data);

        toast.update(toastId, {
          render: "Dados atualizados com sucesso",
          type: "success",
          autoClose: 3000,
        });

        updateUser(response.data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const erros = getValidationErrors(error);

          formRefAccess.current?.setErrors(erros);
          toast.update(toastId, {
            render: "Favor, verifique os campos",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao atualizar seus dados. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 3000,
        });
      }
    },
    [updateUser],
  );

  const handleSubmitProfile = useCallback(async data => {
    formRefProfile.current?.setErrors({});

    const uid_validation =
      data.cnpj.length > 11
        ? Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório")
            .test("test-cnpj", "CNPJ deve ser válido", value => {
              if (value) {
                return cnpjValidation(value);
              }

              return false;
            })
        : Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório")
            .test("test-cpf", "CPF deve ser válido", value => {
              if (value) {
                return cpfValidation(value);
              }

              return false;
            });

    const schema = Yup.object().shape({
      name: Yup.string().required("Campo obrigatório"),
      cnpj: uid_validation,
      phone: Yup.string().required("Campo obrigatório"),
      inscricao_estadual: Yup.string().required("Campo obrigatório"),
      contact_email: Yup.string().email().required("Campo obrigatório"),
      address: Yup.object().shape({
        country: Yup.string().required("Campo obrigatório"),
        state: Yup.string().required("Campo obrigatório"),
        city: Yup.string().required("Campo obrigatório"),
        zipcode: Yup.string().required("Campo obrigatório"),
        neighborhood: Yup.string().required("Campo obrigatório"),
        street: Yup.string().required("Campo obrigatório"),
        complement: Yup.string().required("Campo obrigatório"),
        number: Yup.string().required("Campo obrigatório"),
      }),
    });

    const toastId = toast.info("Criando solicitação...", {
      autoClose: false,
    });

    try {
      await schema.validate(data, { abortEarly: false });

      await api.post("/profiles/updates", data);

      toast.update(toastId, {
        render: "Solicitação criada com sucesso. Em breve ela será analisada.",
        type: "success",
        autoClose: 6000,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRefProfile.current?.setErrors(errors);

        toast.update(toastId, {
          render: "Favor verifique os campos.",
          type: "error",
          autoClose: 3000,
        });

        return;
      }

      toast.update(toastId, {
        render: `Falha ao criar solicitação. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 6000,
      });
    }
  }, []);

  const handleLoadCEPData = useCallback(async () => {
    const zipcode = formRefProfile.current?.getFieldValue("address.zipcode");

    setIsLoadingCEPData(true);
    const { data } = await Axios.get(
      `https://brasilapi.com.br/api/cep/v1/${zipcode}`,
    ).finally(() => {
      setIsLoadingCEPData(false);
    });

    const address = {
      zipcode: data.cep,
      state: data.state,
      city: data.city,
      neighborhood: data.neighborhood,
      street: data.street,
    };

    const formProfileData = formRefProfile.current?.getData();

    formRefProfile.current?.setData({
      ...formProfileData,
      address,
    });
  }, []);

  return (
    <Container>
      <FormContent>
        <h1>Acesso</h1>
        <Form
          onSubmit={handleSubmitAccess}
          initialData={user}
          ref={formRefAccess}
        >
          <InputsContainer>
            <h2>Dados de acesso</h2>
            <hr />

            <div>
              <Input
                label="E-mail"
                name="email"
                type="email"
                placeholder="Digite o e-mail"
              />
              <Input
                label="Senha atual da conta"
                name="password"
                type="password"
                placeholder="Sua senha atual"
              />
              <Input
                label="Nova senha"
                name="new_password"
                type="password"
                placeholder="Digite a nova senha"
              />
              <Input
                label="Confirmação de senha"
                name="new_password_confirmation"
                type="password"
                placeholder="Confirme a nova senha"
              />
            </div>
          </InputsContainer>

          <Button type="submit">Atualizar dados de acesso</Button>
        </Form>
      </FormContent>

      <FormContent>
        <h1 id="profile">Perfil</h1>
        <Form
          onSubmit={handleSubmitProfile}
          initialData={user}
          ref={formRefProfile}
        >
          <InputsContainer>
            <h2>Dados da empresa</h2>
            <hr />

            <div>
              <Input
                label="Nome da empresa"
                name="name"
                placeholder="Digite o nome"
              />
              <Input
                label="CPF/CNPJ da empresa"
                name="cnpj"
                // maskType="cnpj"
                // alwaysShowMask
              />

              <InputMask
                label="Telefone de contato"
                name="phone"
                maskType="phone"
                alwaysShowMask
                maskPlaceholder={null}
              />

              <Input
                label="Inscrição estadual da empresa"
                name="inscricao_estadual"
                placeholder="Digite o número"
              />
              <Input
                label="E-mail de contato"
                name="contact_email"
                type="email"
                placeholder="Digite o e-mail"
              />
            </div>
          </InputsContainer>

          <InputsContainer>
            <h2>Endereço</h2>
            <hr />

            <div>
              <Scope path="address">
                <Input
                  label=""
                  name="country"
                  defaultValue="br"
                  containerStyle={{ display: "none" }}
                />
                <InputMask
                  label="CEP"
                  name="zipcode"
                  maskType="cep"
                  alwaysShowMask
                  maskPlaceholder={null}
                  onBlur={handleLoadCEPData}
                />
                <Input
                  label="Estado"
                  name="state"
                  maxLength={2}
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite a sigla"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Cidade"
                  name="city"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Bairro"
                  name="neighborhood"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Rua"
                  name="street"
                  placeholder={
                    isLoadingCEPData
                      ? "Buscando informações..."
                      : "Digite o nome"
                  }
                  disabled={isLoadingCEPData}
                />
                <Input
                  label="Número"
                  name="number"
                  placeholder="Digite o número"
                />
                <Input
                  label="Complemento"
                  name="complement"
                  placeholder="Digite o complemento"
                />
              </Scope>
            </div>
          </InputsContainer>

          <Button type="submit">Solicitar alteração de dados</Button>
        </Form>
      </FormContent>

      <Payments />
    </Container>
  );
};

export default Profile;
