import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import { Container, Content } from "./styles";

const SignUpSuccess: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Cadastro realizado com sucesso!</h1>

        <FiCheckCircle size={124} />

        <footer>
          <p>
            Enviaremos instruções para a finalização do seu cadastro via e-mail.
          </p>
          <Link to="/">Voltar à tela de acesso</Link>
        </footer>
      </Content>
    </Container>
  );
};

export default SignUpSuccess;
