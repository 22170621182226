import React from "react";

import { Container } from "./styles";

const Support: React.FC = () => {
  const phone_number = "99999999999";
  const phone_number_formatted = "(99) 9 9999 - 9999";
  const email = "contato@cliqueloque.com.br";

  return (
    <Container>
      <header>
        <h1>
          Em breve será possível abrir chamados diretamente pela plataforma.
        </h1>
      </header>
      <main>
        <h2>Entre em contato pelos meios de contato abaixo:</h2>
        <ul>
          <li>
            E-mail: <a href={`mailto:${email}`}>{email}</a>
          </li>
          {/* <li>
            Telefone:{" "}
            <a href={`tel:+55${phone_number}`}>{phone_number_formatted}</a>
          </li> */}
        </ul>
      </main>
    </Container>
  );
};

export default Support;
