import styled from "styled-components";

export const Container = styled.div`
  background: #fff;

  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 24px;

  text-align: center;

  h1 {
    opacity: 0.6;
  }

  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 32px;

    h2 {
      font-weight: normal;
      margin-bottom: 24px;
    }
  }
`;
