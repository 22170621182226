import React, { useCallback, useMemo } from "react";
import { BiLogOut } from "react-icons/bi";

import { useAuth } from "../../hooks/auth";
import {
  ButtonLogout,
  Container,
  Content,
  NavigationPage,
  NavigationPages,
  UserContent,
} from "./styles";
import logoImage from "../../assets/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { TiThListOutline } from "react-icons/ti";
import { BsFillGearFill } from "react-icons/bs";
import { MdOutlineSupport } from "react-icons/md";

export default function Header() {
  const { user, signOut } = useAuth();
  const location = useLocation();

  function getIsSelected(path: string): boolean {
    return location.pathname.includes(path);
  }

  return (
    <Container>
      <Content>
        <UserContent>
          <img src={logoImage} alt={user.name} />

          <NavigationPages>
            <ul>
              <NavigationPage isSelected={getIsSelected("/dashboard")}>
                <Link to="/dashboard">
                  <RiDashboardFill />
                  <p>Dashboard</p>
                </Link>
              </NavigationPage>

              <NavigationPage isSelected={getIsSelected("/leases")}>
                <Link to="/leases">
                  <TiThListOutline />
                  <p>Locações</p>
                </Link>
              </NavigationPage>

              <NavigationPage isSelected={getIsSelected("/products-list")}>
                <Link to="/products-list">
                  <FaListAlt />
                  <p>Produtos</p>
                </Link>
              </NavigationPage>

              <NavigationPage isSelected={getIsSelected("/support")}>
                <Link to="/support">
                  <MdOutlineSupport />
                  <p>Suporte</p>
                </Link>
              </NavigationPage>

              <NavigationPage isSelected={getIsSelected("/profile")}>
                <Link to="/profile">
                  <BsFillGearFill />
                  <p>Configurações</p>
                </Link>
              </NavigationPage>
            </ul>
          </NavigationPages>
        </UserContent>

        <UserContent>
          {/* <button>Notificações</button> */}
          <div>
            <strong>Boas vindas, {user.name}</strong>
          </div>
          {/* <img
            src="https://cdn.imgbin.com/2/8/19/imgbin-computer-icons-user-profile-avatar-avatar-WXX8hueXWtzF4cYqAeN7UnSgP.jpg"
            alt={user.name}
          /> */}
          <ButtonLogout type="button" onClick={signOut}>
            <BiLogOut size={20} />
            <p>Sair</p>
          </ButtonLogout>
        </UserContent>
      </Content>
    </Container>
  );
}
