import React, { useCallback, useEffect, useState } from "react";

import ImageList from "./ImageList";
import { Container } from "./styles";
import Upload from "./Upload";

interface IProductImageProps {
  image_url?: string;
  process_image: Function;
}

const ProductImage: React.FC<IProductImageProps> = ({
  image_url,
  process_image,
}) => {
  const [uploadFiles, setUploadFile] = useState<File>();

  const handleUpload = useCallback(
    async files => {
      const file = files[0];

      setUploadFile(file);
      process_image(file);
    },
    [process_image],
  );

  return (
    <Container>
      <p>Foto do produto</p>

      <ImageList file={uploadFiles} image_url={image_url} />

      <Upload onUpload={handleUpload} />
    </Container>
  );
};

export default ProductImage;
