import React from "react";
import { Redirect, Switch } from "react-router-dom";

import ForgotPassword from "../pages/Auth/ForgotPassword";
import RegisterPassword from "../pages/Auth/RegisterPassword";
import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import SignUpSuccess from "../pages/Auth/SignUpSuccess";
import Dashboard from "../pages/Dashboard";
import Leases from "../pages/Leases";
import OrderSolicitations from "../pages/OrderSolicitations";
import ProductItem from "../pages/ProductItem";
import Products from "../pages/Products";
import Profile from "../pages/Profile";
import Support from "../pages/Support";
import Route from "./Route";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up-success" component={SignUpSuccess} />
      <Route path="/register-password" component={RegisterPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />

      <Route exact path="/dashboard" isPrivate component={Dashboard} />
      <Route
        path="/dashboard/order-solicitations"
        isPrivate
        component={OrderSolicitations}
      />
      <Route path="/leases" isPrivate component={Leases} />
      <Route path="/products-list" isPrivate component={Products} />
      <Route
        exact
        path="/products/:product_id"
        isPrivate
        component={ProductItem}
      />
      <Route path="/products" isPrivate component={ProductItem} />
      <Route path="/support" isPrivate component={Support} />
      <Route path="/profile" isPrivate component={Profile} />

      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
