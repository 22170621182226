import React, { useCallback, useEffect, useState } from "react";

import { addDays, differenceInMinutes, parseISO } from "date-fns";

import formatDate from "../../../utils/formatDate";
import { Container } from "./styles";

export interface IOrderData {
  customer: {
    name: string;
  };
  id: number;
  product_name: string;
  quantity: number;
  initial_lease_date: string;
  rental_days: number;
  created_at: string;
  expires_in: string;
  delivery_address: {
    id: number;
    state: string;
    city: string;
    zipcode: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
  };
}

interface IOrderItemProps {
  order: IOrderData;
  refusedOrderProduct: Function;
  approveOrderProduct: Function;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  order,
  refusedOrderProduct,
  approveOrderProduct,
}) => {
  const [dateTimeExpiration, setDateTimeExpiration] = useState("");
  const [orderExpired, setOrderExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateDateTimeExpiration = useCallback(() => {
    const minutes_left = differenceInMinutes(
      parseISO(order.expires_in),
      new Date(),
    );

    const minutes = minutes_left % 60;
    const hours = Math.floor(minutes_left / 60);

    if (minutes < 0) {
      setOrderExpired(true);
      setDateTimeExpiration("Expirado");
      return;
    }

    const times =
      hours < 1 ? `${minutes} minutos` : `${hours} horas e ${minutes} minutos`;
    const formatted_time_left = `Expira em: <strong>${times}</strong>`;

    setDateTimeExpiration(formatted_time_left);
  }, [order.expires_in]);

  useEffect(() => {
    updateDateTimeExpiration();
  }, [updateDateTimeExpiration]);

  useEffect(() => {
    const interval_id = setInterval(() => {
      updateDateTimeExpiration();
    }, 60000);

    return () => clearInterval(interval_id);
  }, [updateDateTimeExpiration]);

  return (
    <Container>
      <p>
        Número do pedido: <strong>{order.id}</strong>
      </p>
      <section>
        <p>
          <strong>{order.product_name}</strong>
        </p>
        <p>
          Quantidade: <strong>{order.quantity}</strong>
        </p>
      </section>
      <section>
        <p>
          Data para a solicitação:{" "}
          <strong>{formatDate(order.initial_lease_date, true)}</strong>
        </p>
        <p>
          Data de recolhimento:{" "}
          <strong>
            {addDays(
              parseISO(order.initial_lease_date),
              order.rental_days,
            ).toLocaleDateString()}
          </strong>
        </p>
        <p>
          Alugando por: <strong>{order.rental_days} dias</strong>
        </p>
      </section>

      <section>
        <p>
          Cliente: <strong>{order.customer.name}</strong>
        </p>

        <p>
          Endereço:{" "}
          <strong>
            {`${order.delivery_address.state}, ${order.delivery_address.city}, ${order.delivery_address.street}, ${order.delivery_address.neighborhood}, ${order.delivery_address.number}, ${order.delivery_address.complement}`}
          </strong>
        </p>
      </section>

      <footer>
        {isLoading && <p>Processando transação...</p>}

        {!orderExpired && !isLoading && (
          <div>
            <button
              onClick={() => {
                setIsLoading(true);

                refusedOrderProduct(order.id);
              }}
            >
              Recusar
            </button>
            <button
              onClick={() => {
                setIsLoading(true);

                approveOrderProduct(order.id);
              }}
            >
              Confirmar
            </button>
          </div>
        )}

        <p dangerouslySetInnerHTML={{ __html: dateTimeExpiration }}></p>
      </footer>
    </Container>
  );
};

export default OrderItem;
