import React, { useCallback, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import api from "../../../services/api";
import getValidationErrors from "../../../utils/getValidationErrors";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, Content } from "../styles";
import Button from "../../../components/Button";

const RegisterPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const token = useLocation().search.split("?token=")[1];
  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string()
          .required("Campo obrigatório")
          .min(8, "A senha deve conter no minímo 8 dígitos"),
        password_confirmation: Yup.string().when("password", {
          is: value => value.length,

          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "As senhas devem ser iguais",
          ),
        }),
      });

      const toast_id = toast.info("Criando senha...", { autoClose: false });

      try {
        await schema.validate(data, { abortEarly: false });

        await api.post("/passwords/register", data, {
          params: { token },
        });

        toast.update(toast_id, {
          render: "Senha criada com sucesso. Faça login agora",
          type: "success",
          autoClose: 3000,
        });

        history.push("/");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.update(toast_id, {
            render: "Favor verifique os campos",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toast_id, {
          render: `Falha ao criar senha. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [history, token],
  );

  return (
    <Container>
      <Content>
        <h2>Redefinir senha</h2>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            label="Nova senha"
            name="password"
            type="password"
            placeholder="Digite a senha"
          />
          <Input
            label="Confirme a senha"
            name="password_confirmation"
            type="password"
            placeholder="Digite sua senha"
          />

          <Button type="submit">Redefinir senha</Button>
        </Form>

        <section>
          <p>Ainda não tenho uma conta</p>
          <Link to="/sign-up">crie ela aqui</Link>
        </section>
      </Content>
    </Container>
  );
};

export default RegisterPassword;
