import { shade } from "polished";
import styled, { css } from "styled-components";
import Button from "../Button";

export const Container = styled.header`
  padding: 8px 16px;
  background: #fff;

  border-bottom: 1px solid #c5d0da;
`;

export const Content = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    margin-right: 8px;
  }

  img {
    width: 56px;
    /* border-radius: 50%; */
  }
`;

export const NavigationPages = styled.nav`
  display: flex;
  margin-left: 24px;
  /* justify-content: space-between; */
  /* align-items: center; */

  /* position: sticky;

  top: 32px; */

  ul {
    display: flex;
    font-size: 2rem;
  }
`;

interface INavigationPageProps {
  isSelected: boolean;
}

export const NavigationPage = styled.li<INavigationPageProps>`
  padding: 8px 16px;
  border-radius: 8px;

  font-size: 1.4rem;
  font-weight: bold;

  ${({ isSelected }) =>
    isSelected
      ? css`
          background: #0d455f;

          svg,
          p {
            color: #fff;
          }
        `
      : css``}

  a {
    display: flex;
    align-items: center;
    color: #0d455f;

    p {
      margin-left: 4px;
    }
  }

  & + li {
    margin-left: 16px;
  }
`;

export const ButtonLogout = styled(Button)`
  display: flex;
  align-items: center;

  color: #da2020;
  background: none;

  font-weight: bold;

  &:hover {
    background: none;
    color: ${shade(0.2, "#da2020")};
  }

  p {
    margin-left: 4px;
  }
`;
