import React, {
  useEffect,
  useRef,
  CSSProperties,
  useState,
  useCallback,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";

import { useField } from "@unform/core";

import { Container, Error } from "../styles";

interface IInputCurrencyProps extends CurrencyInputProps {
  name: string;
  label: string;
  containerStyle?: CSSProperties;
}

const InputCurrency: React.FC<IInputCurrencyProps> = ({
  label,
  name,
  containerStyle = {},
  ...rest
}) => {
  const inputRef = useRef(null);

  const formatValueWithoutMask = useCallback(value => {
    let inputValueFormatted = value;

    inputValueFormatted = inputValueFormatted.replaceAll(".", "");
    inputValueFormatted = inputValueFormatted.replace(",", ".");
    inputValueFormatted = inputValueFormatted.replace("R$ ", "");

    return inputValueFormatted;
  }, []);

  const { defaultValue, error, fieldName, registerField } = useField(name);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: any) {
        return Number(formatValueWithoutMask(ref.value));
      },
      setValue(ref: any, value: any) {
        setInputValue(String(value));
      },
    });
  }, [registerField, fieldName, formatValueWithoutMask]);

  return (
    <Container isErrored={!!error} style={containerStyle}>
      <label htmlFor={fieldName}>{label}</label>
      <CurrencyInput
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        value={inputValue}
        onValueChange={value => {
          setInputValue(value || "");
        }}
        decimalSeparator=","
        groupSeparator="."
        prefix="R$ "
        placeholder="R$ 0,00"
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputCurrency;
