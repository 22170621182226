import React, { useCallback, useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import Select from "../../../components/form/Input/Select";
import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import { cnpjValidation } from "../../../utils/cnpjValidation";
import { cpfValidation } from "../../../utils/cpfValidation";
import getValidationErrors from "../../../utils/getValidationErrors";
import handleMessageError from "../../../utils/handleMessageError";
import { FormContent, InputsContainer } from "../styles";
import bank_codes from "./bank_codes_data.json";
import Button from "../../../components/Button";

const Payments: React.FC = () => {
  const [alreadyRegister, setAlreadyRegister] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { user, updateUser } = useAuth();

  useEffect(() => {
    api.get("/profiles/recipients").then(response => {
      const { data } = response;

      setAlreadyRegister(true);
      formRef.current?.setData(data);
    });
    setAlreadyRegister(false);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      console.log(data);
      formRef.current?.setErrors({});

      const document_number_validation =
        data.bank_account.document_number.length > 11
          ? Yup.string()
              .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
              .required("Campo obrigatório")
              .test("test-cnpj", "CNPJ deve ser válido", value => {
                if (value) {
                  return cnpjValidation(value);
                }

                return false;
              })
          : Yup.string()
              .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
              .required("Campo obrigatório")
              .test("test-cpf", "CPF deve ser válido", value => {
                if (value) {
                  return cpfValidation(value);
                }

                return false;
              });

      const schema = Yup.object().shape({
        bank_account: Yup.object().shape({
          document_number: document_number_validation,
          bank_code: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .length(3, "Campo obrigatório")
            .test("test-bank_code", "Deve ser um banco válido", value => {
              const isValid = bank_codes.find(
                bank => Number(bank.code) === Number(value),
              );

              return !!isValid;
            }),
          agencia: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório"),
          agencia_dv: Yup.string().matches(
            /^[0-9]+$|^$/,
            "Deve ser apenas dígitos",
          ),
          conta: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório"),
          conta_dv: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório"),
          type: Yup.string().required("Campo obrigatório"),
          legal_name: Yup.string().required("Campo obrigatório"),
        }),
      });

      const dataFormatted = {
        ...data,
        bank_account: {
          ...data.bank_account,
          bank_code: data.bank_account.bank_code.split(" - ")[0],
        },
      };

      console.log({
        dataFormatted,
      });

      const messageStatus = alreadyRegister ? "Atualizando" : "Criando";

      const toastId = toast.info(`${messageStatus} dados bancários...`, {
        autoClose: false,
      });

      try {
        await schema.validate(dataFormatted, { abortEarly: false });

        if (!alreadyRegister) {
          await api.post("/profiles/recipients", dataFormatted);

          setAlreadyRegister(true);
          updateUser({ ...user, registered_account_bank: true });

          toast.update(toastId, {
            render: "Dados bancários criados com sucesso",
            type: "success",
            autoClose: 3000,
          });
        } else {
          await api.put("/profiles/recipients", dataFormatted);

          toast.update(toastId, {
            render: "Dados bancários atualizados com sucesso",
            type: "success",
            autoClose: 3000,
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const erros = getValidationErrors(error);

          formRef.current?.setErrors(erros);
          toast.update(toastId, {
            render: "Favor, verifique os campos",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao criar os dados bancários. ${handleMessageError(
            error,
          )}`,
          type: "error",
          autoClose: 3000,
        });
      }
    },
    [alreadyRegister, updateUser, user],
  );

  return (
    <FormContent>
      <h1 id="payments">Pagamentos</h1>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <InputsContainer>
          <h2>Configurações de transferências</h2>
          <hr />

          <div>
            <Input label="" name="id" containerStyle={{ display: "none" }} />
            <Input
              readOnly
              label="Periodicidade da transferência"
              name="transfer_interval"
              defaultValue="Mensal"
            />

            <Input
              readOnly
              label="Dia da transferência"
              name="transfer_day"
              defaultValue="5"
            />
          </div>
          <p style={{ textAlign: "center", marginTop: "8px" }}>
            *Em breve haverá mais opções
          </p>
        </InputsContainer>

        <InputsContainer>
          <h2>Dados bancários</h2>
          <hr />

          <div>
            <Scope path="bank_account">
              <Input
                label="Número do CPF/CNPJ do titular"
                name="document_number"
                placeholder="Digite o número"
                autoComplete="off"
                maxLength={14}
                disabled={alreadyRegister}
              />

              <Input
                label="Banco"
                name="bank_code"
                placeholder="Selecione o banco"
                autoComplete="on"
                type="search"
                list="bank_codes"
              />
              <datalist id="bank_codes">
                {bank_codes.map(bank_code => (
                  <option
                    key={bank_code.code}
                    value={`${bank_code.code} - ${bank_code.name}`}
                  />
                ))}
              </datalist>
              <Input
                label="Agência"
                name="agencia"
                placeholder="Digite o número da agência"
                autoComplete="off"
                maxLength={4}
              />
              <Input
                label="Digito da agência (opcional)"
                name="agencia_dv"
                placeholder="Digite o digito verificador da agência"
                autoComplete="off"
                maxLength={1}
              />
              <Input
                label="Conta"
                name="conta"
                placeholder="Digite o número da conta"
                autoComplete="off"
                maxLength={13}
              />
              <Input
                label="Dígito da conta"
                name="conta_dv"
                placeholder="Digite o dígito verificador da conta"
                autoComplete="off"
                maxLength={2}
              />

              <Select
                label="Tipo de conta bancária"
                name="type"
                optionValues={[
                  { label: "Conta Corrente", value: "conta_corrente" },
                  { label: "Conta Poupança", value: "conta_poupanca" },
                  {
                    label: "Conta Corrente Conjunta",
                    value: "conta_corrente_conjunta",
                  },
                  {
                    label: "Conta Poupança Conjunta",
                    value: "conta_poupanca_conjunta",
                  },
                ]}
                defaultValue="conta_corrente"
              />

              <Input
                label="Nome/Razão social do dono da conta"
                name="legal_name"
                placeholder="Digite o nome"
                maxLength={30}
                autoComplete="off"
              />
            </Scope>
          </div>
        </InputsContainer>

        <Button type="submit">
          {alreadyRegister ? "Atualizar " : "Cadastrar "}
          conta bancária
        </Button>
      </Form>
    </FormContent>
  );
};

export default Payments;
