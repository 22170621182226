import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../components/form/Input";
import InputCurrency from "../../components/form/Input/InputCurrency";
import Select from "../../components/form/Input/Select";
import Textarea from "../../components/form/Textarea";
import api from "../../services/api";
import getValidationErrors from "../../utils/getValidationErrors";
import handleMessageError from "../../utils/handleMessageError";
import ProductImage from "./ProductImage";
import {
  Container,
  ValuesContainer,
  PriceContainer,
  CustomValues,
  RemoveButton,
  AddCustomValueButton,
  ButtonsContainer,
} from "./styles";
import Button from "../../components/Button";

interface IProductData {
  name: string;
  description: string;
  category_id: number;
  quantity: number;
  is_period_rental: boolean;
  daily_rates: Array<{
    id?: number;
    price: string;
    minimum_days: number;
  }>;
  freights: Array<{
    id?: number;
    price: string;
    minimum_days: number;
  }>;
  product_image: string;
}

interface ICategoryData {
  id: number;
  name: string;
}

const ProductItem: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [lengthCustomDailyValues, setLengthCustomDailyValues] = useState<{}[]>(
    [],
  );
  const [lengthCustomFreightValues, setLengthCustomFreightValues] = useState<
    {}[]
  >([]);
  const [productData, setProductData] = useState<{} | any>();
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const [categories, setCategories] = useState<ICategoryData[]>([]);
  const [isPeriodRental, setIsPeriodRental] = useState(true);

  const history = useHistory();
  const { product_id } = useParams<{ product_id: string }>();

  useEffect(() => {
    async function loadCategories() {
      const { data } = await api.get("/categories");
      setCategories(data);
    }

    loadCategories();
  }, []);

  useEffect(() => {
    if (product_id) {
      api
        .get(`/products/${product_id}`)
        .then(async response => {
          const data_formatted = {
            name: response.data.name,
            quantity: response.data.quantity,
            category_id: response.data.category_id,
            description: response.data.description,
            daily_rates: response.data.custom_daily_rates,
            freights: response.data.custom_freights,
            product_image: response.data.product_images[0]?.image_url,
            is_period_rental: response.data.is_period_rental,
          } as IProductData;

          setLengthCustomDailyValues(
            new Array(data_formatted.daily_rates.length - 1).fill({}),
          );
          setLengthCustomFreightValues(
            new Array(data_formatted.freights.length - 1).fill({}),
          );

          setProductData(data_formatted);
          setIsPeriodRental(data_formatted.is_period_rental);
        })
        .catch(err => {
          console.log(err);

          toast.error(`Falha ao carregar produto. ${handleMessageError(err)}`);
        });
    }
  }, [product_id]);

  useEffect(() => {
    if (product_id) {
      const hasFreights =
        !!productData && lengthCustomFreightValues.length >= 0;
      const hasDailyRates =
        !!productData && lengthCustomDailyValues.length >= 0;

      if (!!productData && hasFreights && hasDailyRates && !alreadyLoaded) {
        setAlreadyLoaded(true);
        formRef.current?.setData(productData);
      }
    }
  }, [
    product_id,
    productData,
    lengthCustomDailyValues,
    lengthCustomFreightValues,
    alreadyLoaded,
  ]);

  const handleProcessImage = useCallback(
    async (file: File, newProduct?: number) => {
      const formData = new FormData();
      formData.append("image", file, file.name);

      const toastId = toast.info(
        `${product_id ? "Atualizando" : "Cadastrando"} imagem...`,
        {
          autoClose: false,
        },
      );

      const product_id_to_use = newProduct || product_id;

      try {
        await api.post(`products/${product_id_to_use}/images`, formData);

        toast.update(toastId, {
          render: `Imagem ${
            product_id ? "atualizada" : "cadastrada"
          } com sucesso`,
          type: "success",
          autoClose: 3000,
        });
      } catch (error) {
        toast.update(toastId, {
          render: `Erro ao ${
            product_id ? "atualizar" : "cadastrar"
          }. Favor, tente novamente. \n\n${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [product_id],
  );

  const handleSubmit = useCallback(
    async (data: IProductData) => {
      const {
        name,
        category_id,
        quantity,
        description,
        daily_rates,
        freights,
        is_period_rental,
      } = data;

      console.log(data);

      formRef.current?.setErrors({});

      const toastId = toast.info(
        `${product_id ? "Atualizando" : "Cadastrando"} produto...`,
        {
          autoClose: false,
        },
      );

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        category_id: Yup.string().required("Campo obrigatório"),
        description: Yup.string().required("Campo obrigatório"),
        is_period_rental: Yup.boolean().required("Campo obrigatório"),
        daily_rates: Yup.array().of(
          Yup.object().shape({
            price: Yup.number().required("Campo obrigatório"),
            minimum_days: Yup.number().required("Campo obrigatório"),
          }),
        ),
        freights: Yup.array().of(
          Yup.object().shape({
            price: Yup.number().required("Campo obrigatório"),
            minimum_days: Yup.number().required("Campo obrigatório"),
          }),
        ),
      });

      const daily_rates_formatted = daily_rates.map(item => ({
        ...(item.id ? { id: item.id } : {}),
        minimum_days: Number(item.minimum_days),
        price: Number(item.price),
      }));

      const freights_formatted = freights.map(item => ({
        ...(item.id ? { id: item.id } : {}),
        minimum_days: Number(item.minimum_days),
        price: Number(item.price),
      }));

      const dataFormatted = {
        name,
        category_id,
        quantity,
        description,
        is_period_rental,
        daily_rates: daily_rates_formatted,
        freights: freights_formatted,
      };

      try {
        await schema.validate(dataFormatted, { abortEarly: false });

        let newProductCreatedId;

        if (product_id) {
          await api.put(`/products/${product_id}`, dataFormatted);
        } else {
          const response = await api.post("/products", dataFormatted);

          newProductCreatedId = response.data.id;
        }

        if (currentFile) {
          await handleProcessImage(currentFile, newProductCreatedId);
        }

        toast.update(toastId, {
          render: `Produto ${
            product_id ? "atualizado" : "cadastrado"
          } com sucesso`,
          type: "success",
          autoClose: 3000,
        });

        history.push("/products-list");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.update(toastId, {
            render: "Favor verifique os campos",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Erro ao ${
            product_id ? "atualizar" : "cadastrar"
          }. Favor, tente novamente. \n\n${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [product_id, history, handleProcessImage, currentFile],
  );

  const handleRemoveProduct = useCallback(async () => {
    const toast_id = toast.info("Deletando produto...", {
      autoClose: false,
    });

    try {
      await api.delete(`/products/${product_id}`);

      toast.update(toast_id, {
        render: "Produto deletado com sucesso!",
        type: "success",
        autoClose: 3000,
      });

      history.push("/products-list");
    } catch (error) {
      toast.update(toast_id, {
        render: `Falha ao deletar produto. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 3000,
      });
    }
  }, [product_id, history]);

  const handleAddCustomDailyRate = useCallback(() => {
    setLengthCustomDailyValues(state => [...state, {}]);
  }, []);

  const handleAddCustomFreight = useCallback(() => {
    setLengthCustomFreightValues(state => [...state, {}]);
  }, []);

  const handleRemoveCustomDailyRate = useCallback(
    async (event, position) => {
      const daily_rate_id =
        event.currentTarget.parentElement?.firstElementChild?.children[1].value;

      const toast_id = toast.info("Deletando diária...", { autoClose: false });

      try {
        if (daily_rate_id) {
          await api.delete(
            `/products/${product_id}/daily_rates/${daily_rate_id}`,
          );
        }

        const form_data = formRef.current?.getData() as IProductData;

        form_data.daily_rates.splice(position, 1);

        formRef.current?.setData({
          ...form_data,
          daily_rates: form_data.daily_rates,
        });

        setLengthCustomDailyValues(
          new Array(form_data.daily_rates.length - 1).fill({}),
        );

        toast.update(toast_id, {
          render: "Diária deletada com sucesso!",
          type: "success",
          autoClose: 3000,
        });
      } catch (error) {
        toast.update(toast_id, {
          render: `Falha ao deletar diária. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [product_id],
  );

  const handleRemoveCustomFreight = useCallback(
    async (event, position) => {
      const freight_id =
        event.currentTarget.parentElement?.firstElementChild?.children[1].value;

      const toast_id = toast.info("Deletando diária...", { autoClose: false });

      try {
        if (freight_id) {
          await api.delete(`/products/${product_id}/freights/${freight_id}`);
        }

        const form_data = formRef.current?.getData() as IProductData;

        form_data.freights.splice(position, 1);

        formRef.current?.setData({
          ...form_data,
          freights: form_data.freights,
        });

        setLengthCustomFreightValues(
          new Array(form_data.freights.length - 1).fill({}),
        );

        toast.update(toast_id, {
          render: "Frete deletada com sucesso!",
          type: "success",
          autoClose: 3000,
        });
      } catch (error) {
        toast.update(toast_id, {
          render: `Falha ao deletar frete. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [product_id],
  );

  return (
    <Container>
      <main>
        <h1>Cadastrar novo produto</h1>

        <ProductImage
          image_url={productData?.product_image}
          process_image={(file: File) => {
            setCurrentFile(file);
          }}
        />

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            label="Nome do produto"
            name="name"
            placeholder="Digite o nome"
          />

          <section className="two-columns">
            <Select
              label="Produto disponível"
              name="quantity"
              defaultValue={1}
              optionValues={[
                { label: "Sim", value: 1 },
                { label: "Não", value: 0 },
              ]}
            />

            <Select
              label="Categoria"
              name="category_id"
              customMessageSelect="Selecione uma categoria"
              optionValues={categories.map(category => ({
                label: category.name,
                value: category.id,
              }))}
            />
          </section>

          {/* <Input
            label="Quantidade do produto"
            name="quantity"
            type="number"
            step={1}
            min={0}
            defaultValue={0}
            placeholder="Digite a quantidade"
          /> */}
          <Textarea
            label="Observações/Especificações do produto"
            name="description"
            placeholder="Digite a descrição do produto"
          />

          <ValuesContainer>
            <h2>Valores do aluguel</h2>

            <header>
              <Select
                label="Modo de aluguel"
                name="is_period_rental"
                value={isPeriodRental ? "true" : "false"}
                onChange={e => {
                  setIsPeriodRental(e.target.value === "true");
                }}
                optionValues={[
                  { label: "Diária", value: "false" },
                  { label: "Período", value: "true" },
                ]}
              />
            </header>

            <PriceContainer>
              <Scope path="daily_rates[0]">
                <div>
                  <Input
                    label=""
                    name="id"
                    containerStyle={{ display: "none" }}
                  />

                  <InputCurrency
                    label={`Valor ${
                      isPeriodRental ? "do período" : "da diária"
                    }`}
                    name="price"
                  />

                  <Input
                    label={`${
                      isPeriodRental
                        ? "Por quantos dias"
                        : "A partir de quantos dias"
                    }`}
                    name="minimum_days"
                    type="number"
                    min={0}
                    defaultValue={0}
                    placeholder="Digite a quantidade"
                    containerStyle={!isPeriodRental ? { display: "none" } : {}}
                  />

                  {lengthCustomDailyValues.length > 0 && (
                    <RemoveButton
                      type="button"
                      onClick={event => {
                        handleRemoveCustomDailyRate(event, 0);
                      }}
                    >
                      <FiTrash2 size={16} />
                      Remover
                    </RemoveButton>
                  )}
                </div>
              </Scope>

              <CustomValues>
                <p>Valores personalizados</p>

                {lengthCustomDailyValues.map((_, index) => (
                  <Scope path={`daily_rates[${index + 1}]`} key={index}>
                    <div>
                      <Input
                        label="id"
                        name="id"
                        containerStyle={{ display: "none" }}
                      />

                      <InputCurrency
                        label={`Valor ${
                          isPeriodRental ? "do período" : "da diária"
                        }`}
                        name="price"
                      />

                      <Input
                        label={`${
                          isPeriodRental
                            ? "Por quantos dias"
                            : "A partir de quantos dias"
                        }`}
                        name="minimum_days"
                        type="number"
                        min={0}
                        defaultValue={0}
                        placeholder="Digite a quantidade"
                      />

                      <RemoveButton
                        type="button"
                        onClick={event => {
                          handleRemoveCustomDailyRate(event, index + 1);
                        }}
                      >
                        <FiTrash2 size={16} />
                        Remover
                      </RemoveButton>
                    </div>
                  </Scope>
                ))}

                <AddCustomValueButton
                  onClick={handleAddCustomDailyRate}
                  type="button"
                >
                  Cadastrar valor personalizado
                </AddCustomValueButton>
              </CustomValues>
            </PriceContainer>
          </ValuesContainer>

          <ValuesContainer>
            <h2>Valores do frete</h2>

            <Scope path="freights[0]">
              <div>
                <Input
                  label="id"
                  name="id"
                  containerStyle={{ display: "none" }}
                />
                <InputCurrency label="Valor do frete" name="price" />

                <Input
                  label="A partir de quantos dias"
                  name="minimum_days"
                  type="number"
                  min={0}
                  defaultValue={0}
                  placeholder="Digite a quantidade"
                />

                {lengthCustomFreightValues.length > 0 && (
                  <RemoveButton
                    type="button"
                    onClick={event => {
                      handleRemoveCustomFreight(event, 0);
                    }}
                  >
                    <FiTrash2 size={16} />
                    Remover
                  </RemoveButton>
                )}
              </div>
            </Scope>

            <CustomValues>
              <p>Valores personalizados</p>
              {lengthCustomFreightValues.map((_, index) => (
                <Scope path={`freights[${index + 1}]`} key={index}>
                  <div>
                    <Input
                      label=""
                      name="id"
                      containerStyle={{ display: "none" }}
                    />

                    <InputCurrency label="Valor do frete" name="price" />

                    <Input
                      label="A partir de quantos dias"
                      name="minimum_days"
                      type="number"
                      min={0}
                      defaultValue={0}
                      placeholder="Digite a quantidade"
                    />

                    <RemoveButton
                      type="button"
                      onClick={event => {
                        handleRemoveCustomFreight(event, index + 1);
                      }}
                    >
                      <FiTrash2 size={16} />
                      Remover
                    </RemoveButton>
                  </div>
                </Scope>
              ))}
              <AddCustomValueButton
                onClick={handleAddCustomFreight}
                type="button"
              >
                Cadastrar valor personalizado
              </AddCustomValueButton>
            </CustomValues>
          </ValuesContainer>

          <ButtonsContainer>
            {product_id && (
              <Button
                type="button"
                onClick={handleRemoveProduct}
                className="delete"
              >
                <FiTrash2 size={20} />
                Remover produto
              </Button>
            )}

            <Button type="submit">
              {product_id ? "Atualizar" : "Cadastrar"} produto
            </Button>
          </ButtonsContainer>
        </Form>
      </main>
    </Container>
  );
};

export default ProductItem;
