// import styled from "styled-components";

// export const Container = styled.div`
//   padding: 4px 8px;
//   border-radius: 8px;
//   background: #fff;

//   width: 100%;

//   div {
//     display: flex;
//     align-items: center;

//     border: 1px solid rgba(0, 0, 0, 0.5);

//     input {
//       border: 0;
//       font-size: 1.6rem;
//       color: #7888b6;
//       &::placeholder {
//         opacity: 0.4;
//       }
//     }
//   }
// `;

import styled, { css } from "styled-components";

interface IContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  font-size: 1.6rem;

  label {
    font-weight: 500;
  }

  select {
    margin-top: 4px;
    padding: 8px 16px;

    border: 0;
    background: #f5f5f5;

    ${props =>
      props.isErrored &&
      css`
        border: 1px solid ${props.isErrored && "#ed4337"};
      `}

    border-radius: 4px;

    width: 100%;

    font-size: 1.8rem;

    svg {
      margin-right: 8px;
      opacity: 0.7;
    }

    &::placeholder {
      opacity: 0.6;
    }
  }
`;

export const Error = styled.span`
  color: #ed4337;
  margin-top: 4px;
  font-weight: 500;
`;
