import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #fff;
  border: 1px solid #c5d0da;
  border-radius: 8px;

  padding: 40px;

  h1 {
    margin-bottom: 48px;
    font-weight: normal;
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 64px;

    a {
      margin-top: 32px;

      color: #0d455f;
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;
