import styled from "styled-components";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #eee;

  border-radius: 8px;

  padding: 8px 32px 24px;
  width: 100%;
  max-width: 450px;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    h1 {
      margin-bottom: auto;
    }

    button {
      font-size: 2rem;
      margin-left: auto;
    }
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    margin-top: 24px;

    button {
      font-weight: 700;
      margin-top: 24px;
    }
  }
`;
