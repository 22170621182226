import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

import api from "../../services/api";
import formatDate from "../../utils/formatDate";
import { Container, ProductContainer } from "./styles";

interface IProductData {
  id: number;
  name: string;
  slug: string;
  description: string;
  quantity: number;
  product_image: string;
  created_at: string;
  updated_at: string;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<IProductData[]>([]);

  useEffect(() => {
    api.get("/products").then(response => {
      const product_formatted = response.data.map((product: any) => ({
        ...product,
        product_image:
          product.product_images[0]?.image_url ||
          "https://ctkbiotech.com/wp/wp-content/uploads/2018/03/not-available.jpg",
      }));

      setProducts(product_formatted);
    });
  }, []);

  return (
    <Container>
      <h1>Produtos</h1>

      <header>
        <Link to="/products">
          <FaPlus size={24} />
          Novo produto
        </Link>
      </header>

      <main>
        <ul>
          {products.map(product => (
            <ProductContainer key={product.id}>
              <p>{product.id}</p>

              <div>
                <img src={product.product_image} alt={product.name} />

                <div>
                  <section>
                    <p>
                      Equipamento: <strong>{product.name}</strong>
                    </p>
                    <p>
                      Quantidade: <strong>{product.quantity}</strong>
                    </p>
                  </section>

                  <section>
                    <p>
                      Data de castramento:
                      <strong> {formatDate(product.created_at)}</strong>
                    </p>
                    <p>
                      Data da ultima atualização:
                      <strong> {formatDate(product.updated_at)}</strong>
                    </p>
                  </section>
                </div>

                <Link to={`/products/${product.id}`}>
                  <MdKeyboardArrowRight size={40} />
                </Link>
              </div>
            </ProductContainer>
          ))}
        </ul>
      </main>
    </Container>
  );
};

export default Products;
