import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 32px 96px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #f4f4f4;
  border-radius: 8px;

  width: 100%;

  > h1 {
    color: #0d455f;
    font-size: 3.2rem;
    font-weight: 700;
  }

  header {
    display: flex;

    width: 100%;

    margin-bottom: 32px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 8px 12px;
      margin-left: auto;

      border-radius: 8px;

      background: #0d455f;

      color: #fff;

      svg {
        margin-right: 4px;
      }

      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, "#0d455f")};
      }
    }
  }

  ul {
    max-width: 800px;
  }
`;

export const ProductContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;

  border-radius: 8px;
  width: 100%;

  padding: 16px 32px;

  box-shadow: 0px 4px 10px 4px rgba(13, 69, 95, 0.25);

  > p {
    font-weight: 700;
    font-size: 2.4rem;

    text-align: center;

    color: #0d455f;
    margin-bottom: 16px;
  }

  > div {
    display: flex;
    width: 100%;
  }

  img {
    height: 104px;
    width: 128px;
    border-radius: 8px;

    margin-right: 32px;
  }

  section {
    margin-top: 8px;
  }

  a {
    color: #fff;

    background: #0d455f;

    align-self: center;
    height: min-content;
    margin-left: auto;

    border-radius: 4px;

    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, "#0d455f")};
    }
  }

  & + li {
    margin-top: 16px;
  }
`;
