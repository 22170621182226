import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { Container } from "./styles";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

export default function Button({ children, ...rest }: IButtonProps) {
  return (
    <Container type="button" {...rest}>
      {children}
    </Container>
  );
}
