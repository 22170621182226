import { shade } from "polished";
import styled, { css } from "styled-components";
import Button from "../../components/Button";

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 16px 32px;

  main {
    max-width: 560px;
    width: 100%;

    h1 {
      font-weight: 700;
      color: #0d455f;
      text-align: center;
      margin-bottom: 32px;
    }

    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  form {
    textarea {
      min-height: 104px;
      max-height: 224px;

      resize: vertical;
    }

    > section.two-columns {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 16px;

      div + div {
        margin-top: 0;

        margin-left: 16px;
      }
    }

    div + div {
      margin-top: 16px;
    }
  }
`;

export const ValuesContainer = styled.section`
  margin-top: 32px;

  h2 {
    margin-bottom: 16px;
  }

  header {
    display: flex;
    margin-bottom: 16px;
  }

  div {
    display: flex;

    margin-bottom: 8px;
    margin-right: 16px;

    div + div {
      margin-top: 0px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomValues = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 700;

    margin-bottom: 8px;
  }
`;

export const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  background: #d7586b;

  &:hover {
    background: ${shade(0.2, "#d7586b")};
  }

  font-size: 1.4rem;

  margin-top: 12px;

  svg {
    margin-right: 4px;
  }
`;

export const AddCustomValueButton = styled(Button)`
  padding: 8px 16px;
  margin: 0 auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  button {
    font-size: 2.4rem;
    margin-top: 40px;
  }

  button.delete {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #d7586b;

    &:hover {
      background: ${shade(0.2, "#d7586b")};
    }

    svg {
      margin-right: 4px;
    }
  }

  button:last-child {
    margin-left: auto;
  }
`;
