import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;

  h1 {
    margin-bottom: 80px;
    color: #fff;
    font-style: italic;
    font-size: 6.4rem;
  }
`;

export const Content = styled.div`
  border: 1px solid #000;
  border-radius: 8px;

  background: #fff;

  padding: 16px 64px;

  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 520px;
  width: 100%;

  h2 {
    margin-bottom: 32px;
    color: #0d455f;
    font-size: 3.2rem;
  }

  form {
    width: 100%;
    max-width: 320px;

    div + div {
      margin-top: 16px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      padding: 8px 16px;
      border-radius: 8px;
      margin: 24px 0 40px;

      font-size: 2rem;

      svg {
        margin-right: 8px;
      }
    }
  }

  section {
    text-align: center;

    a {
      text-decoration: underline;
      color: #000c76;
    }

    & + section {
      margin-top: 24px;
    }
  }
`;
