import { shade } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;

  border-radius: 8px;
  box-shadow: 0px 4px 10px 4px rgba(13, 69, 95, 0.25);

  padding: 8px 16px;

  section {
    margin-top: 8px;
  }

  footer {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 16px;

    > p {
      margin-bottom: 8px;
    }

    div {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 8px;

      button {
        border-radius: 8px;
        padding: 8px 16px;

        color: #ffffff;
        font-weight: 700;
        background: #0d455f;

        &:hover {
          background: ${shade(0.2, "#0d455f")};
        }

        &:first-child {
          background: #da2020;

          &:hover {
            background: ${shade(0.2, "#da2020")};
          }
        }
      }
    }
  }
`;
