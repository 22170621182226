import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";

import api from "../../services/api";
import formatDate from "../../utils/formatDate";
import handleMessageError from "../../utils/handleMessageError";
import { Container, ButtonFilter, LeaseItem, ButtonsContainer } from "./styles";

interface ILeaseData {
  id: number;
  retreat_date: string;
  retreat_confirmation_at: string;
  order: {
    id: number;
    order_id: string;
    product_id: number;
    product_name: string;
    quantity: number;
    initial_lease_date: string;
    rental_days: number;
    created_at: string;
    delivery_address: {
      id: number;
      state: string;
      city: string;
      zipcode: string;
      neighborhood: string;
      street: string;
      number: string;
      complement: string;
    };
    customer: {
      name: string;
      phone: string;
    };
  };
}

const Leases: React.FC = () => {
  const [leases, setLeases] = useState<ILeaseData[]>([]);

  const [filter, setFilter] = useState("to_use");

  useEffect(() => {
    api
      .get("/leases", {
        params: {
          status: filter,
        },
      })
      .then(response => {
        setLeases(response.data);
      });
  }, [filter]);

  const handleConfirmDelivered = useCallback(async id => {
    const toast_id = toast.info("Confirmando entrega...", {
      autoClose: false,
    });

    try {
      await api.post(`/leases/${id}/confirm_lease_delivered`);

      toast.dismiss(toast_id);
      setLeases(state => state.filter(lease => lease.id !== id));
    } catch (error) {
      toast.update(toast_id, {
        render: `Falha ao confirmar entrega. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 5000,
      });
    }
  }, []);

  const handleConfirmRetreated = useCallback(async id => {
    const toast_id = toast.info("Confirmando recolhimento...", {
      autoClose: false,
    });

    try {
      await api.post(`/leases/${id}/confirm_lease_retreated`);

      toast.dismiss(toast_id);
      setLeases(state => state.filter(lease => lease.id !== id));
    } catch (error) {
      toast.update(toast_id, {
        render: `Falha ao confirmar recolhimento. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 5000,
      });
    }
  }, []);

  return (
    <Container>
      <header>
        <nav>
          <ButtonFilter
            selected={filter === "to_use"}
            onClick={() => {
              setFilter("to_use");
            }}
          >
            A ser entregue
          </ButtonFilter>
          <ButtonFilter
            selected={filter === "using"}
            onClick={() => {
              setFilter("using");
            }}
          >
            Em utilização
          </ButtonFilter>
          <ButtonFilter
            selected={filter === "used"}
            onClick={() => {
              setFilter("used");
            }}
          >
            Histórico de utilização
          </ButtonFilter>
        </nav>
      </header>
      <main>
        <ul>
          {leases.map(lease => (
            <LeaseItem key={lease.id}>
              <p>
                Número da locação: <strong>{lease.id}</strong>
              </p>
              <section>
                <p>
                  Equipamento:
                  <strong> {lease.order.product_name}</strong>
                </p>
                <p>
                  Quantidade: <strong>{lease.order.quantity}</strong>
                </p>
              </section>

              <section>
                <p>
                  Data de solicitação:{" "}
                  <strong>{formatDate(lease.order.created_at)}</strong>
                </p>
                <p>
                  Data para a entrega:{" "}
                  <strong>
                    {formatDate(lease.order.initial_lease_date, true)}
                  </strong>
                </p>
                {filter !== "to_use" && (
                  <p>
                    Data de recolhimento:{" "}
                    <strong>{formatDate(lease.retreat_date, true)}</strong>
                  </p>
                )}
                {filter === "used" && (
                  <p>
                    Recolhido em:{" "}
                    <strong>
                      {formatDate(lease.retreat_confirmation_at, true)}
                    </strong>
                  </p>
                )}
              </section>
              <section>
                <p>
                  Cliente:
                  <strong> {lease.order.customer.name}</strong>
                </p>
                <p>
                  Telefone de contato:
                  <strong> {lease.order.customer.phone}</strong>
                </p>
                <p>
                  Endereço:
                  <strong>
                    {` ${lease.order.delivery_address.state}, ${lease.order.delivery_address.city}, ${lease.order.delivery_address.street}, ${lease.order.delivery_address.neighborhood}, ${lease.order.delivery_address.number}, ${lease.order.delivery_address.complement}`}
                  </strong>
                </p>
              </section>

              {filter === "to_use" && (
                <ButtonsContainer>
                  <Button
                    type="button"
                    onClick={() => {
                      handleConfirmDelivered(lease.id);
                    }}
                  >
                    Confirmar entrega
                  </Button>
                </ButtonsContainer>
              )}

              {filter === "using" && (
                <ButtonsContainer>
                  <Button
                    type="button"
                    onClick={() => {
                      handleConfirmRetreated(lease.id);
                    }}
                  >
                    Confirmar recolhimento
                  </Button>
                  {/* <button type="button" disabled>
                    Relatar problemas com o recolhimento
                  </button> */}
                </ButtonsContainer>
              )}

              {/* {filter === "used" && (
                <ButtonsContainer>
                  <strong>Avaliação do cliente</strong>
                  <p>Sem avaliação</p>
                </ButtonsContainer>
              )} */}
            </LeaseItem>
          ))}
        </ul>
      </main>
    </Container>
  );
};

export default Leases;
