import styled from "styled-components";

export const Container = styled.div`
  padding: 8px 40px 16px;

  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const Content = styled.div`
  background: #fff;
  border: 1px solid #c5d0da;
  border-radius: 8px;

  padding: 16px 96px;
  margin: 0 auto;

  max-width: 800px;

  h1 {
    color: #0d455f;
    margin-bottom: 32px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    padding: 8px 72px;
    font-weight: 500;
    font-size: 2rem;

    border-radius: 8px;

    margin: 40px auto;
  }

  footer {
    text-align: center;

    a {
      color: #0d455f;
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

export const InputsContainer = styled.section`
  h2 {
    margin-bottom: 16px;
    font-weight: bold;
    color: #0d455f;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px 16px;

    margin: 0 auto;
  }

  & + section {
    margin-top: 32px;
  }
`;

export const AcceptContainer = styled.section`
  display: flex;

  margin: 40px auto 0 !important;

  p {
    margin-left: 8px;

    a {
      text-decoration: underline;
    }
  }
`;
