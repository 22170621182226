import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  padding: 8px 16px;

  background: #0d455f;

  color: #fff;

  border-radius: 8px;

  &:hover {
    background: ${shade(0.2, "#0d455f")};
  }
`;
