import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services/api";
import handleMessageError from "../../utils/handleMessageError";
import Modal from "./Modal";
import OrderItem, { IOrderData } from "./OrderItem";
import { Container } from "./styles";

const OrderSolicitations: React.FC = () => {
  const [orders, setOrders] = useState<IOrderData[]>([]);
  const [currentOrderProductId, setCurrentOrderProductId] = useState(0);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    api.get("/orders").then(response => {
      setOrders(response.data);
    });
  }, []);

  useEffect(() => {
    const minutes = 10 * 60 * 1000;

    const interval_id = setInterval(() => {
      toast.info(
        "Pode ser que você tenha novas solicitações. Atualize a página de solicitações ou vá até ela",
        {
          autoClose: false,
          type: "info",
          toastId: "UPDATE_PAGE_TO_GET_MORE_SOLICITATIONS",
        },
      );

      return () => clearInterval(interval_id);
    }, minutes);
  }, []);

  const handleDeclineOrderProduct = useCallback(
    async description => {
      const toast_id = toast.info("Recusando pedido...", { autoClose: false });
      try {
        await api.post(`/orders/${currentOrderProductId}/decline`, {
          description,
        });

        setOrders(state =>
          state.filter(order => order.id !== currentOrderProductId),
        );

        setModalIsOpen(false);

        toast.dismiss(toast_id);
      } catch (error) {
        toast.update(toast_id, {
          render: `Erro ao recusar pedido. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [currentOrderProductId],
  );

  const handleApproveOrderProduct = useCallback(async order_id => {
    const toast_id = toast.info("Aprovando pedido...", { autoClose: false });

    try {
      await api.post(`/orders/${order_id}/approve`);

      setOrders(state => state.filter(order => order.id !== order_id));

      toast.dismiss(toast_id);
    } catch (error) {
      toast.update(toast_id, {
        render: `Erro ao aprovar pedido. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 5000,
      });
    }
  }, []);

  return (
    <Container>
      <h1>Solicitações de equipamentos</h1>
      <main>
        {orders.map(order => (
          <OrderItem
            key={order.id}
            order={order}
            approveOrderProduct={handleApproveOrderProduct}
            refusedOrderProduct={(order_product_id: number) => {
              setCurrentOrderProductId(order_product_id);
              setModalIsOpen(true);
            }}
          />
        ))}
      </main>

      {modalIsOpen && (
        <Modal
          submitForm={handleDeclineOrderProduct}
          order_product_id={currentOrderProductId}
          closeModal={() => {
            setModalIsOpen(false);
          }}
        />
      )}
    </Container>
  );
};

export default OrderSolicitations;
