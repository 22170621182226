import styled, { css } from "styled-components";

interface IButtonFilterProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #f4f4f4;
  border-radius: 8px;

  padding: 8px;

  header {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    margin-bottom: 16px;

    h1 {
      align-self: flex-start;
      margin-bottom: 24px;
    }

    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 32px;

      button {
        color: #0d455f;
        font-weight: 700;
        font-size: 2.4rem;
      }

      button + button {
        margin-left: 32px;
      }
    }
  }

  main {
    display: flex;
    justify-content: center;
    width: 100%;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 16px;

      width: 100%;
    }
  }
`;

export const ButtonFilter = styled.button<IButtonFilterProps>`
  background: transparent;

  padding-bottom: 4px;
  font-size: 2rem;

  ${props =>
    props.selected &&
    css`
      border-bottom: 4px solid #0d455f;
      color: #0d455f;
    `}
`;

export const LeaseItem = styled.li`
  display: flex;
  flex-direction: column;

  background: #ffffff;

  box-shadow: 0px 4px 10px 4px rgba(13, 69, 95, 0.25);
  border-radius: 8px;

  padding: 8px;

  font-size: 1.6rem;

  section {
    margin-top: 8px;

    div {
      display: flex;

      p + p {
        margin-left: 16px;
      }
    }
  }
`;

export const ButtonsContainer = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    margin-top: auto;
  }
`;
