import styled, { css } from "styled-components";

interface IContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  input {
    margin-top: 4px;
    padding: 8px 16px;

    border: 0;

    ${props =>
      props.isErrored &&
      css`
        border: 1px solid ${props.isErrored && "#ed4337"};
      `}

    border-radius: 8px;
    background: #f5f5f5;

    &::placeholder {
      opacity: 0.6;
    }
  }
`;

export const Error = styled.span`
  color: #ed4337;
  margin-top: 4px;
  font-weight: 500;
`;
