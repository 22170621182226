import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Select from "../../../components/form/Input/Select";
import getValidationErrors from "../../../utils/getValidationErrors";
import { Container, Content } from "./styles";
import Button from "../../../components/Button";

interface IModalProps {
  order_product_id: number;
  submitForm: Function;
  closeModal: Function;
}

const Modal: React.FC<IModalProps> = ({
  order_product_id,
  submitForm,
  closeModal,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string().required("Selecione o motivo"),
      });

      try {
        await schema.validate(data, { abortEarly: false });

        submitForm(data.description);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.error("Favor verifique os campos");
        }
      }
    },
    [submitForm],
  );

  return (
    <Container>
      <Content>
        <header>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
          <h1>Recusa do pedido {order_product_id}</h1>
        </header>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            label="Selecione o motivo de recusa"
            name="description"
            optionValues={[
              { label: "Empresa sem estoque", value: "Empresa sem estoque" },
              {
                label: "Empresa não atende essa localidade",
                value: "Empresa não atende essa localidade",
              },
            ]}
          />

          <Button type="submit">Confirmar</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Modal;
