import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const FileInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 24px 0;

  img {
    width: 40%;
    border-radius: 8px;
  }
`;
