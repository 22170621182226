import styled from "styled-components";

export const Container = styled.div`
  background: #f4f4f4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 32px 96px;

  section {
    width: 100%;

    h1 {
      font-weight: 700;
      font-size: 3.2rem;
      color: #0d455f;
      text-align: center;
      margin-bottom: 32px;
    }

    div {
      p {
        margin-top: 8px;
      }
    }

    footer {
      display: flex;

      margin-top: 24px;

      button {
        display: flex;
        align-items: center;
        flex-direction: column;

        padding: 8px 16px;
        background: #fff;

        border-radius: 8px;

        transition: opacity 0.2s;

        box-shadow: 0px 4px 10px 4px rgba(13, 69, 95, 0.25);

        svg {
          margin-bottom: 8px;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;
