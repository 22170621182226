import React, { useCallback, useRef } from "react";
import { BiLogIn } from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import { useAuth } from "../../../hooks/auth";
import getValidationErrors from "../../../utils/getValidationErrors";
import { Container, Content } from "../styles";
import Button from "../../../components/Button";
import { FaUserLock } from "react-icons/fa";

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email().required("Campo obrigatório"),
        password: Yup.string().required("Campo obrigatório"),
      });

      try {
        await schema.validate(data, { abortEarly: false });

        await signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.error("Favor verifique os campos");
        }
      }
    },
    [signIn],
  );

  return (
    <Container>
      <h1>Boas vindas à Clique Loque</h1>

      <Content>
        <FaUserLock size={140} color="#0D455F" style={{ marginBottom: 40 }} />

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            label="E-mail"
            name="email"
            type="email"
            placeholder="Digite o e-mail"
            autoComplete="off"
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder="Digite sua senha"
          />

          <Button type="submit">
            <BiLogIn />
            Acessar conta
          </Button>
        </Form>

        <section>
          <p>Não tem cadastro ainda?</p>
          <Link to="/sign-up">crie ela aqui</Link>
        </section>

        <section>
          <p>Esqueceu a senha?</p>
          <Link to="/forgot-password">recuperar ela aqui</Link>
        </section>
      </Content>
    </Container>
  );
};

export default SignIn;
