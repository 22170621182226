import React, { useCallback, useEffect } from "react";
import { MdTimelapse } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { Container } from "./styles";

const Dashboard: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    api.get("/profiles");

    if (!user.registered_account_bank) {
      toast.warn("Você precisa cadastrar uma conta bancária primeiro.", {
        autoClose: 8000,
      });

      history.push("/profile?force_register_bank_account=true");
    }
  }, [user.registered_account_bank, history]);

  const handleGoToPageOrdersSolicitations = useCallback(() => {
    history.push("/dashboard/order-solicitations");
  }, [history]);

  return (
    <Container>
      <section>
        <h1>Locação</h1>
        {/* <hr /> */}

        {/* <div>
          <p>
            Entregas pendentes: <strong>0</strong>
          </p>
          <p>
            Equipamentos em utilização: <strong>0</strong>
          </p>
          <p>
            Entregas próximas ao recolhimento: <strong>0</strong>
          </p>
        </div> */}

        <footer>
          <button onClick={handleGoToPageOrdersSolicitations}>
            <MdTimelapse size={40} />
            Solicitações de equipamentos
          </button>
        </footer>
      </section>
    </Container>
  );
};

export default Dashboard;
