import React, { useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import InputMask from "../../../components/form/Input/InputMask";
import api from "../../../services/api";
import getValidationErrors from "../../../utils/getValidationErrors";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, Content } from "../styles";
import Button from "../../../components/Button";

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required("Campo obrigatório"),
        cnpj: Yup.string().required("Campo obrigatório"),
      });

      const toast_id = toast.info("Criando solicitação...", {
        autoClose: false,
      });

      try {
        await schema.validate(data, { abortEarly: false });

        await api.post("/passwords/forgot", data);

        toast.update(toast_id, {
          render:
            "Solicitação criada com sucesso. Em breve recebera um e-mail com as instruções",
          type: "success",
          autoClose: 6000,
        });

        history.push("/");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.update(toast_id, {
            render: "Favor verifique os campos",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toast_id, {
          render: `Falha ao criar solicitação. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <h2>Recuperar senha</h2>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            label="E-mail"
            name="email"
            type="email"
            placeholder="Digite o e-mail"
          />
          <InputMask
            label="CNPJ da empresa"
            name="cnpj"
            maskType="cnpj"
            alwaysShowMask
            maskPlaceholder={null}
            placeholder="Digite o CNPJ da empresa"
          />

          <Button type="submit">Recuperar senha</Button>
        </Form>

        <section>
          <Link to="/sign">Voltar para tela de acesso</Link>
        </section>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
